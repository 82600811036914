/*
@tailwind base;

@tailwind components;
*/

.bg-black {
  background-color: #22292F
}

.bg-red-700 {
  background-color: #D32F2F
}

.bg-red {
  background-color: #F44336
}

.bg-orange {
  background-color: #FF9800
}

.bg-green-700 {
  background-color: #388E3C
}

.bg-green-800 {
  background-color: #2E7D32
}

.bg-green {
  background-color: #4CAF50
}

.bg-blue-700 {
  background-color: #1976D2
}

.bg-blue-800 {
  background-color: #1565C0
}

.bg-blue {
  background-color: #2196F3
}

.bg-purple-300 {
  background-color: #BA68C8
}

.bg-purple-700 {
  background-color: #7B1FA2
}

.bg-purple {
  background-color: #9C27B0
}

.bg-pink {
  background-color: #E91E63
}

.hover\:bg-transparent:hover {
  background-color: transparent
}

.focus\:bg-transparent:focus {
  background-color: transparent
}

.rounded-8 {
  border-radius: .8rem
}

.rounded-12 {
  border-radius: 1.2rem
}

.rounded {
  border-radius: .4rem
}

.rounded-md {
  border-radius: .6rem
}

.rounded-lg {
  border-radius: .8rem
}

.border-1 {
  border-width: 1px
}

.border {
  border-width: 1px
}

.border-b-1 {
  border-bottom-width: 1px
}

.cursor-default {
  cursor: default
}

.cursor-pointer {
  cursor: pointer
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: -webkit-box;
  display: flex
}

.table {
  display: table
}

.hidden {
  display: none
}

.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row
}

.flex-row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
          flex-direction: row-reverse
}

.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.items-start {
  -webkit-box-align: start;
          align-items: flex-start
}

.items-end {
  -webkit-box-align: end;
          align-items: flex-end
}

.items-center {
  -webkit-box-align: center;
          align-items: center
}

.justify-end {
  -webkit-box-pack: end;
          justify-content: flex-end
}

.justify-center {
  -webkit-box-pack: center;
          justify-content: center
}

.justify-between {
  -webkit-box-pack: justify;
          justify-content: space-between
}

.flex-1 {
  -webkit-box-flex: 1;
          flex: 1 1 0%
}

.flex-auto {
  -webkit-box-flex: 1;
          flex: 1 1 auto
}

.flex-grow-0 {
  -webkit-box-flex: 0;
          flex-grow: 0
}

.flex-grow {
  -webkit-box-flex: 1;
          flex-grow: 1
}

.flex-shrink-0 {
  flex-shrink: 0
}

.flex-shrink {
  flex-shrink: 1
}

.font-300 {
  font-weight: 300
}

.font-600 {
  font-weight: 600
}

.font-light {
  font-weight: 300
}

.font-medium {
  font-weight: 500
}

.font-bold {
  font-weight: 700
}

.h-0 {
  height: 0
}

.h-8 {
  height: 0.8rem
}

.h-16 {
  height: 1.6rem
}

.h-40 {
  height: 4rem
}

.h-48 {
  height: 4.8rem
}

.h-64 {
  height: 6.4rem
}

.h-72 {
  height: 7.2rem
}

.h-96 {
  height: 9.6rem
}

.h-200 {
  height: 20rem
}

.h-400 {
  height: 40rem
}

.h-full {
  height: 100%
}

.leading-none {
  line-height: 1
}

.m-8 {
  margin: 0.8rem
}

.m-10 {
  margin: 1.0rem
}

.m-12 {
  margin: 1.2rem
}

.m-16 {
  margin: 1.6rem
}

.mx-0 {
  margin-left: 0;
  margin-right: 0
}

.mx-4 {
  margin-left: 0.4rem;
  margin-right: 0.4rem
}

.mx-8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem
}

.mx-12 {
  margin-left: 1.2rem;
  margin-right: 1.2rem
}

.my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem
}

.mx-16 {
  margin-left: 1.6rem;
  margin-right: 1.6rem
}

.my-20 {
  margin-top: 2rem;
  margin-bottom: 2rem
}

.my-24 {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.-mx-12 {
  margin-left: -1.2rem;
  margin-right: -1.2rem
}

.mt-0 {
  margin-top: 0
}

.mb-0 {
  margin-bottom: 0
}

.mb-4 {
  margin-bottom: 0.4rem
}

.mt-8 {
  margin-top: 0.8rem
}

.mr-10 {
  margin-right: 1.0rem
}

.mb-10 {
  margin-bottom: 1.0rem
}

.mb-12 {
  margin-bottom: 1.2rem
}

.mt-16 {
  margin-top: 1.6rem
}

.mb-16 {
  margin-bottom: 1.6rem
}

.mt-20 {
  margin-top: 2rem
}

.mr-20 {
  margin-right: 2rem
}

.mb-24 {
  margin-bottom: 2.4rem
}

.mt-32 {
  margin-top: 3.2rem
}

.mb-32 {
  margin-bottom: 3.2rem
}

.mt-48 {
  margin-top: 4.8rem
}

.mb-48 {
  margin-bottom: 4.8rem
}

.mt-64 {
  margin-top: 6.4rem
}

.mb-64 {
  margin-bottom: 6.4rem
}

.mt-96 {
  margin-top: 9.6rem
}

[dir='ltr'] .ltr\:ml-4,[dir='ltr'].ltr\:ml-4 {
  margin-left: 0.4rem
}

[dir='ltr'] .ltr\:ml-8,[dir='ltr'].ltr\:ml-8 {
  margin-left: 0.8rem
}

[dir='rtl'] .rtl\:mr-4,[dir='rtl'].rtl\:mr-4 {
  margin-right: 0.4rem
}

[dir='rtl'] .rtl\:mr-8,[dir='rtl'].rtl\:mr-8 {
  margin-right: 0.8rem
}

.max-w-400 {
  max-width: 40rem
}

.max-w-512 {
  max-width: 51.2rem
}

.max-w-xs {
  max-width: 32rem
}

.max-w-2xl {
  max-width: 112rem
}

.max-w-full {
  max-width: 100%
}

.min-h-64 {
  min-height: 6.4rem
}

.min-h-72 {
  min-height: 7.2rem
}

.min-h-200 {
  min-height: 20rem
}

.min-h-400 {
  min-height: 40rem
}

.min-w-0 {
  min-width: 0
}

.min-w-20 {
  min-width: 2rem
}

.min-w-40 {
  min-width: 4rem
}

.min-w-64 {
  min-width: 6.4rem
}

.min-w-xl {
  min-width: 96rem
}

.opacity-50 {
  opacity: 0.5
}

.overflow-hidden {
  overflow: hidden
}

.overflow-x-auto {
  overflow-x: auto
}

.p-0 {
  padding: 0
}

.p-4 {
  padding: 0.4rem
}

.p-8 {
  padding: 0.8rem
}

.p-10 {
  padding: 1.0rem
}

.p-12 {
  padding: 1.2rem
}

.p-16 {
  padding: 1.6rem
}

.p-24 {
  padding: 2.4rem
}

.p-32 {
  padding: 3.2rem
}

.p-88 {
  padding: 8.8rem
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0
}

.px-0 {
  padding-left: 0;
  padding-right: 0
}

.py-4 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem
}

.py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem
}

.px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem
}

.py-12 {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem
}

.px-12 {
  padding-left: 1.2rem;
  padding-right: 1.2rem
}

.py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem
}

.px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem
}

.px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem
}

.pb-0 {
  padding-bottom: 0
}

.pb-4 {
  padding-bottom: 0.4rem
}

.pt-8 {
  padding-top: 0.8rem
}

.pb-8 {
  padding-bottom: 0.8rem
}

.pl-10 {
  padding-left: 1.0rem
}

.pb-16 {
  padding-bottom: 1.6rem
}

.pl-16 {
  padding-left: 1.6rem
}

.pt-24 {
  padding-top: 2.4rem
}

.pb-24 {
  padding-bottom: 2.4rem
}

.pl-24 {
  padding-left: 2.4rem
}

.pb-28 {
  padding-bottom: 2.8rem
}

.pt-32 {
  padding-top: 3.2rem
}

.pl-40 {
  padding-left: 4rem
}

.pl-56 {
  padding-left: 5.6rem
}

.pb-64 {
  padding-bottom: 6.4rem
}

.pl-72 {
  padding-left: 7.2rem
}

.pl-80 {
  padding-left: 8rem
}

[dir='ltr'] .ltr\:pr-48,[dir='ltr'].ltr\:pr-48 {
  padding-right: 4.8rem
}

[dir='rtl'] .rtl\:pl-48,[dir='rtl'].rtl\:pl-48 {
  padding-left: 4.8rem
}

.pointer-events-none {
  pointer-events: none
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: -webkit-sticky;
  position: sticky
}

.top-0 {
  top: 0
}

.right-0 {
  right: 0
}

.bottom-0 {
  bottom: 0
}

.left-0 {
  left: 0
}

[dir='ltr'] .ltr\:right-0,[dir='ltr'].ltr\:right-0 {
  right: 0
}

[dir='rtl'] .rtl\:left-0,[dir='rtl'].rtl\:left-0 {
  left: 0
}

.resize {
  resize: both
}

.shadow-none {
  box-shadow: none
}

.text-center {
  text-align: center
}

.text-black {
  color: #22292F
}

.text-white {
  color: #FFF
}

.text-red {
  color: #F44336
}

.text-green {
  color: #4CAF50
}

.text-11 {
  font-size: 1.1rem
}

.text-12 {
  font-size: 1.2rem
}

.text-13 {
  font-size: 1.3rem
}

.text-14 {
  font-size: 1.4rem
}

.text-16 {
  font-size: 1.6rem
}

.text-20 {
  font-size: 2rem
}

.text-32 {
  font-size: 3.2rem
}

.text-56 {
  font-size: 5.6rem
}

.italic {
  font-style: italic
}

.uppercase {
  text-transform: uppercase
}

.capitalize {
  text-transform: capitalize
}

.normal-case {
  text-transform: none
}

.underline {
  text-decoration: underline
}

.visible {
  visibility: visible
}

.invisible {
  visibility: hidden
}

.whitespace-no-wrap {
  white-space: nowrap
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.w-16 {
  width: 1.6rem
}

.w-24 {
  width: 2.4rem
}

.w-32 {
  width: 3.2rem
}

.w-40 {
  width: 4rem
}

.w-48 {
  width: 4.8rem
}

.w-64 {
  width: 6.4rem
}

.w-80 {
  width: 8rem
}

.w-128 {
  width: 12.8rem
}

.w-xs {
  width: 32rem
}

.w-xl {
  width: 96rem
}

.w-1\/4 {
  width: 25%
}

.w-full {
  width: 100%
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.z-9999 {
  z-index: 9999
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  -webkit-transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
          transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
}

.transition {
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform
}

@media (min-width: 600px) {

  .sm\:flex {
    display: -webkit-box;
    display: flex
  }

  .sm\:hidden {
    display: none
  }

  .sm\:items-start {
    -webkit-box-align: start;
            align-items: flex-start
  }

  .sm\:h-136 {
    height: 13.6rem
  }

  .sm\:h-256 {
    height: 25.6rem
  }

  .sm\:h-320 {
    height: 32rem
  }

  .sm\:m-0 {
    margin: 0
  }

  .sm\:mx-12 {
    margin-left: 1.2rem;
    margin-right: 1.2rem
  }

  .sm\:mb-12 {
    margin-bottom: 1.2rem
  }

  .sm\:min-h-136 {
    min-height: 13.6rem
  }

  .sm\:p-24 {
    padding: 2.4rem
  }

  .sm\:pl-0 {
    padding-left: 0
  }

  .sm\:pb-16 {
    padding-bottom: 1.6rem
  }

  .sm\:text-20 {
    font-size: 2rem
  }

  .sm\:w-48 {
    width: 4.8rem
  }
}

@media (min-width: 960px) {

  .md\:flex {
    display: -webkit-box;
    display: flex
  }

  .md\:flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row
  }

  .md\:items-start {
    -webkit-box-align: start;
            align-items: flex-start
  }

  .md\:flex-1 {
    -webkit-box-flex: 1;
            flex: 1 1 0%
  }

  .md\:flex-shrink-0 {
    flex-shrink: 0
  }

  .md\:m-0 {
    margin: 0
  }

  .md\:mb-0 {
    margin-bottom: 0
  }

  .md\:max-w-256 {
    max-width: 25.6rem
  }

  .md\:p-0 {
    padding: 0
  }

  .md\:p-48 {
    padding: 4.8rem
  }

  .md\:p-128 {
    padding: 12.8rem
  }

  .md\:pt-128 {
    padding-top: 12.8rem
  }

  .md\:text-left {
    text-align: left
  }

  .md\:w-full {
    width: 100%
  }
}

@media (min-width: 1280px) {

  .lg\:pr-0 {
    padding-right: 0
  }

  [dir='ltr'] .lg\:ltr\:pl-0,[dir='ltr'].lg\:ltr\:pl-0 {
    padding-left: 0
  }

  [dir='rtl'] .lg\:rtl\:pr-0,[dir='rtl'].lg\:rtl\:pr-0 {
    padding-right: 0
  }

  .lg\:invisible {
    visibility: hidden
  }
}

@media print {

  .print\:mb-12 {
    margin-bottom: 1.2rem
  }

  .print\:p-0 {
    padding: 0
  }
}